.login-wrapper {
  width: 480px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.login-logo {
  width: 420px;
  background-color: #622599;
  margin-bottom: 1em;
}

.login-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: auto;
  padding: 2em;
  background-color: white;
}

.login-field {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 2em;
}

.login-field label {
  margin-bottom: 0.5em;
}

.login-field input {
  border: 3px solid #d4d4d4;
  padding: 0.75em;
}

.login-field input.login-field-failed {
  border-color: red;
}

.login-field span {
  font-size: 0.8em;
  font-weight: 700;
  color: red;
  margin-top: 0.5em;
}

.login-buttons {
  display: flex;
  flex-direction: row-reverse;
  gap: 1.5em;
}

.login-buttons button {
  align-self: center;
  background-color: #622599;
  color: white;
  padding: 0.75em 1.5em;
  border: none;
}

.login-buttons .login-button-secondary {
  background-color: #d4d4d4;
  color: black;
}
